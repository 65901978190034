import React from "react"

import { trailingslashit } from "../components/helpers"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
library.add(fab)

const SocialLink = ({ icon, url, label, className }) => (
  <a
    href={trailingslashit(url)}
    target="_blank"
    rel="noopener noreferrer"
    alt={`Join Alloy on ${label}`}
    className={className}
  >
    <FontAwesomeIcon icon={["fab", icon]} size="lg" />
  </a>
)

export default SocialLink
