import React from 'react'
import Button from 'react-bootstrap/Button'
import { trailingslashit } from './helpers'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

const CTAs = ({ctas, className, shouldTrailingSlashIt = true}) => (
    ctas.map(cta => {
        const color = cta.color ? cta.color : 'primary'
        let variant = (type => {
            switch(type) {
                case 'solid':
                    return color
                case 'outline':
                    return `${type}-${color}`
                case 'link':
                default:
                    return type
            }
        })(cta.type)

        var ctaUrl = shouldTrailingSlashIt ? trailingslashit(cta.url) : cta.url;

        return(
            <Button
                key={cta.label}
                variant={variant}
                href={ctaUrl}
                target={cta.target}
                className={className}
            >
                {cta.label}
                {cta.icon ? <FontAwesomeIcon icon={cta.icon} /> : null}
            </Button>
        )
    })
)

export default CTAs