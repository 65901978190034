import React from "react"

const SectionHeading = ({
  crown,
  heading,
  subhead,
  align,
  className,
  crownColor,
  ...remainingProps
}) => {
  const alignment = typeof align !== "undefined" ? align : "center"
  const textAlign = `text-${alignment}`

  return (
    <div className={className} {...remainingProps}>
      {crown ? (
        <p className={`crown ${textAlign} ${crownColor}`}>{crown}</p>
      ) : null}
      {heading ? <h2 className={`heading ${textAlign}`}>{heading}</h2> : null}
      {subhead ? <p className={`subhead ${textAlign}`}>{subhead}</p> : null}
    </div>
  )
}

export default SectionHeading
