import React from "react"
import { getImgResizeUrl } from "./helpers"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { Image as GatsbyImage } from "gatsby-image"

const Image = props => {
  const url = getImgResizeUrl(props.src, props.resizeWidth, props.resizeHeight)

  return (
    <>
      {props.preload && (
        <link rel="preload" as="image" href={url || props.src} />
      )}
      {url ? (
        <LazyLoadImage
          alt={props.alt}
          {...props}
          src={url}
          threshold={0}
          className={`${props.className} imgLoad`}
        />
      ) : (
        <LazyLoadImage
          alt={props.alt}
          {...props}
          threshold={0}
          className={`${props.className} imgLoad`}
        />
      )}
    </>
  )
}

export default Image
