import React from "react"
import CTAs from "./CTAs"
import Container from "react-bootstrap/Container"
import Header from "./header"
import Image from "./image"

const Hero = ({
  heading,
  subhead,
  ctas,
  image,
  dark,
  video,
  visibleByDefault,
}) => {
  const textColor = dark ? "text-light" : "text-dark"

  const rgbDark = "0,0,0"
  const rgbLight = "255,255,255"
  const opacity = 0.5
  const backgroundColor = dark
    ? `rgba(${rgbDark},${opacity})`
    : `rgba(${rgbLight},${opacity})`

  const style = {
    backgroundColor: backgroundColor,
  }

  return heading ? (
    <section style={style} className="hero">
      <Image
        resizeWidth={1000}
        src={image}
        className="hero__background"
        visibleByDefault={visibleByDefault}
        preload={true}
      />
      {video ? (
        <video
          src={video}
          muted={true}
          autoPlay={true}
          loop={true}
          className="hero__video"
        />
      ) : null}
      <Header dark={dark} />
      <Container className={`${textColor} d-flex align-items-center`}>
        <div className="hero-content">
          <h1>{heading}</h1>
          <p class="lead mb-2">{subhead}</p>
          {ctas ? <CTAs ctas={ctas} className="mr-2 mt-2" /> : null}
        </div>
      </Container>
    </section>
  ) : (
    <Header dark={false} />
  )
}

Hero.defaultProps = {
  dark: true,
}

export default Hero
