import React from 'react'

const Copyright = () => {
    const startYear = new Date('January 1, 2019, 00:00:00').getFullYear()
    const currentYear = new Date().getFullYear()

    const dateRange = startYear === currentYear ? currentYear : `${startYear}–${currentYear}`

    return (
        <p className='small copyright text-muted'>&copy; {dateRange} Alloy | All rights reserved.</p>
    )
}

export default Copyright