import React from "react"
import GoogleMapReact from "google-map-react"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
library.add(faMapMarkerAlt)

const FooterMap = () => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAyXbIZ88ueNZP-eqM94NJ7jGD1YxL0Pa0" }}
        defaultCenter={[33.73633220745727, -85.3864946645032]}
        defaultZoom={7}
        options={maps => ({
          scrollwheel: false,
        })}
      >
        <FontAwesomeIcon
          icon={faMapMarkerAlt}
          size="6x"
          color="#145aa5"
          lat={33.518367}
          lng={-86.798676}
          style={{ transform: "translate(-50%, -100%)" }}
        />
        <FontAwesomeIcon
          icon={faMapMarkerAlt}
          size="6x"
          color="#145aa5"
          lat={33.77332686725305}
          lng={-84.2920621715472}
          style={{ transform: "translate(-50%, -100%)" }}
        />
      </GoogleMapReact>
    </div>
  )
}

export default FooterMap
