import React from 'react'

const Logo = ({ dark }) => {
    const icon = {
        // fill: dark ? "#fff" : "#dc232e"
        fill: "#dc232e"
    }
    
    const type = {
        fill: dark ? "#fff" : "#403538"
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 664 146" style={{ width: 250 }}>
            <g>
                <g style={icon}>
                    <ellipse cx="29.5" cy="112.62" rx="29.5" ry="29.51"/>
                    <path d="M140.24,138.17c-.07,.05-.14,.09-.21,.13-14.11,8.15-32.15,3.32-40.3-10.8-4-6.93-4.87-14.81-3.05-22.01,.03-.14,.07-.28,.11-.41,2.41-9.44,1.29-19.8-3.97-28.91-5.25-9.1-13.66-15.26-23.04-17.89-.14-.03-.28-.07-.41-.11-7.14-2.01-13.52-6.71-17.53-13.64-8.15-14.12-3.32-32.16,10.8-40.32,.07-.04,.14-.08,.22-.12,.07-.05,.14-.09,.21-.13,14.11-8.15,32.15-3.32,40.3,10.8,4,6.94,4.87,14.82,3.05,22.01-.03,.13-.07,.27-.11,.41-2.41,9.44-1.29,19.8,3.97,28.9,5.26,9.11,13.66,15.26,23.04,17.89,.13,.03,.27,.07,.41,.11,7.14,2.02,13.53,6.71,17.53,13.64,8.15,14.12,3.32,32.16-10.8,40.32-.07,.04-.14,.08-.22,.12Z"/>
                </g>
                <g style={type}>
                    <g>
                        <path d="M195.9,119.28c-4.94-5.26-7.4-11.62-7.4-19.08s2.47-13.84,7.4-19.14c4.93-5.3,11.04-7.95,18.32-7.95,5.12,0,9.91,1.59,14.38,4.76,.36-1.37,1.04-2.5,2.05-3.41,1.01-.9,2.25-1.35,3.73-1.35s2.77,.58,3.89,1.73c1.12,1.15,1.68,2.52,1.68,4.11v42.49c0,1.59-.56,2.94-1.68,4.05-1.12,1.12-2.41,1.68-3.89,1.68s-2.72-.43-3.73-1.3c-1.01-.87-1.69-1.98-2.05-3.35-4.4,3.1-9.19,4.65-14.38,4.65-7.28,0-13.38-2.63-18.32-7.89Zm4.16-19.08c0,4.36,1.31,8.02,3.94,10.97,2.63,2.96,6.01,4.43,10.16,4.43s7.57-1.48,10.27-4.43c2.7-2.95,4.05-6.59,4.05-10.92s-1.37-8-4.11-11.03c-2.74-3.03-6.14-4.54-10.21-4.54s-7.44,1.5-10.1,4.49c-2.67,2.99-4,6.67-4,11.03Z"/>
                        <path d="M250.53,57.44c0-1.66,.58-3.06,1.73-4.22,1.15-1.15,2.56-1.73,4.22-1.73s3.08,.58,4.27,1.73c1.19,1.15,1.78,2.56,1.78,4.22v63.79c0,1.66-.6,3.06-1.78,4.22-1.19,1.15-2.61,1.73-4.27,1.73s-3.06-.58-4.22-1.73c-1.15-1.15-1.73-2.56-1.73-4.22V57.44Z"/>
                        <path d="M272.57,57.44c0-1.66,.58-3.06,1.73-4.22,1.15-1.15,2.56-1.73,4.22-1.73s3.08,.58,4.27,1.73c1.19,1.15,1.78,2.56,1.78,4.22v63.79c0,1.66-.6,3.06-1.78,4.22-1.19,1.15-2.61,1.73-4.27,1.73s-3.06-.58-4.22-1.73c-1.15-1.15-1.73-2.56-1.73-4.22V57.44Z"/>
                        <path d="M317.42,127.5c-7.28,0-13.44-2.67-18.48-8-5.05-5.33-7.57-11.71-7.57-19.14s2.54-13.82,7.62-19.19c5.08-5.37,11.18-8.06,18.32-8.06s13.2,2.72,18.21,8.16c5.01,5.44,7.51,11.91,7.51,19.41s-2.52,13.7-7.56,18.6c-4.75,5.48-10.77,8.22-18.05,8.22Zm-10.37-38.49c-2.59,3.03-3.89,6.78-3.89,11.24s1.3,8.29,3.89,11.46c2.81,2.67,6.2,4,10.16,4s7.35-1.33,10.16-4c2.81-3.17,4.22-6.99,4.22-11.46s-1.41-8.22-4.22-11.24c-3.1-2.74-6.48-4.11-10.16-4.11s-7.06,1.37-10.16,4.11Z"/>
                        <path d="M385.3,74.74c1.08-1.08,2.41-1.62,4-1.62s2.95,.56,4.11,1.68c1.15,1.12,1.73,2.47,1.73,4.05v40.87c0,7.21-2.51,13.32-7.51,18.33-5.01,5.01-11.12,7.51-18.32,7.51-3.82,0-7.53-.9-11.13-2.7-1.51-.65-2.52-1.71-3.03-3.19-.5-1.48-.43-2.97,.22-4.49,.72-1.3,1.85-2.2,3.41-2.7,1.55-.51,3.19-.32,4.92,.54s3.89,1.28,6.49,1.24c2.59-.04,5.13-.97,7.62-2.81s4.16-4.16,5.03-6.97c-3.02,1.73-6.45,2.59-10.27,2.59-6.2,0-11.51-2.23-15.94-6.7-4.43-4.47-6.64-9.8-6.64-16v-25.51c0-1.58,.56-2.94,1.67-4.05,1.12-1.12,2.47-1.68,4.05-1.68s2.92,.54,4,1.62c1.08,1.08,1.62,2.45,1.62,4.11v25.51c0,3.1,1.1,5.71,3.3,7.84,2.2,2.13,4.84,3.19,7.94,3.19s5.73-1.06,7.89-3.19c2.16-2.13,3.24-4.74,3.24-7.84v-25.51c0-1.66,.54-3.03,1.62-4.11Z"/>
                    </g>
                    <path d="M459.2,122.75v-7.68c-2.38,3.17-5.33,5.68-8.86,7.52-3.53,1.84-7.35,2.76-11.46,2.76-7.13,0-13.19-2.58-18.16-7.73-4.97-5.15-7.46-11.39-7.46-18.71s2.49-13.57,7.46-18.76c4.97-5.19,11.02-7.78,18.16-7.78,4.11,0,7.93,.94,11.46,2.81,3.53,1.88,6.48,4.4,8.86,7.57v-30.49c0-.72,.27-1.33,.81-1.84,.54-.5,1.17-.76,1.89-.76s1.33,.25,1.84,.76c.5,.51,.76,1.12,.76,1.84V122.75c0,.72-.25,1.34-.76,1.84-.51,.51-1.12,.76-1.84,.76s-1.35-.25-1.89-.76c-.54-.5-.81-1.12-.81-1.84Zm-40.64-23.84c0,5.88,2,10.87,6,14.98,4,4.11,8.75,6.16,14.27,6.16s10.23-1.96,14.16-5.89c3.93-3.93,6-8.7,6.21-14.32v-1.73c-.22-5.69-2.29-10.52-6.21-14.49-3.93-3.96-8.65-5.95-14.16-5.95s-10.27,2.07-14.27,6.22c-4,4.15-6,9.15-6,15.03Z"/>
                    <path d="M474.76,122.75v-47.79c0-.72,.25-1.33,.76-1.84,.5-.5,1.14-.76,1.89-.76s1.39,.25,1.89,.76c.5,.51,.76,1.12,.76,1.84v47.79c0,.72-.27,1.34-.81,1.84-.54,.51-1.17,.76-1.89,.76s-1.34-.25-1.84-.76c-.5-.5-.76-1.12-.76-1.84Z"/>
                    <path d="M537.77,74.97v46.16c0,6.85-2.43,12.7-7.3,17.57-4.86,4.86-10.75,7.3-17.67,7.3-3.53,0-7.2-.83-11.02-2.49-1.44-1.01-1.8-2.16-1.08-3.46,.79-1.66,1.95-2.06,3.46-1.19,2.74,1.3,5.62,1.95,8.64,1.95,5.48,0,10.14-1.91,14-5.73,3.85-3.82,5.78-8.47,5.78-13.95v-6.49c-2.23,3.24-5.08,5.78-8.54,7.62-3.46,1.84-7.2,2.76-11.24,2.76-6.92,0-12.79-2.58-17.62-7.73-4.83-5.15-7.24-11.35-7.24-18.6s2.43-13.44,7.3-18.59c4.86-5.15,10.72-7.73,17.56-7.73,3.96,0,7.69,.94,11.19,2.81,3.49,1.88,6.36,4.4,8.59,7.57v-7.78c0-.72,.27-1.33,.81-1.84,.54-.5,1.15-.76,1.84-.76s1.28,.25,1.78,.76c.5,.51,.76,1.12,.76,1.84Zm-44.64,23.73c0,5.88,1.93,10.88,5.78,15.03,3.85,4.15,8.5,6.22,13.94,6.22s10.09-2.07,13.94-6.22c3.85-4.15,5.78-9.17,5.78-15.08s-1.93-10.9-5.78-14.97c-3.86-4.07-8.5-6.11-13.94-6.11s-10.09,2.06-13.94,6.16c-3.86,4.11-5.78,9.1-5.78,14.97Z"/>
                    <path d="M549.44,122.75v-47.79c0-.72,.25-1.33,.76-1.84,.5-.5,1.14-.76,1.89-.76s1.39,.25,1.89,.76c.5,.51,.76,1.12,.76,1.84v47.79c0,.72-.27,1.34-.81,1.84-.54,.51-1.17,.76-1.89,.76s-1.34-.25-1.84-.76c-.5-.5-.76-1.12-.76-1.84Z"/>
                    <path d="M586.4,72.37c.65,0,1.24,.27,1.78,.81s.81,1.13,.81,1.78-.27,1.24-.81,1.78-1.14,.81-1.78,.81h-6.81v38.6c0,1.15,.45,2.09,1.35,2.81,.9,.72,1.71,1.08,2.43,1.08s1.37,.29,1.95,.87c.58,.58,.86,1.19,.86,1.84s-.29,1.24-.86,1.78c-.58,.54-1.19,.81-1.84,.81-2.67,0-4.88-.87-6.65-2.6-1.77-1.73-2.65-3.93-2.65-6.59v-38.6h-6.59c-1.15,0-1.87-.22-2.16-.65-.29-.43-.43-1.01-.43-1.73s.04-1.22,.11-1.51c.22-.87,1.04-1.3,2.49-1.3h6.59v-19.68c0-.65,.27-1.24,.81-1.78s1.15-.81,1.84-.81,1.31,.27,1.89,.81c.58,.54,.86,1.13,.86,1.78v19.68h6.81Z"/>
                    <path d="M640.22,73.13c.5-.5,1.12-.76,1.84-.76s1.33,.25,1.84,.76c.5,.51,.76,1.12,.76,1.84v47.79c0,.72-.25,1.34-.76,1.84s-1.12,.76-1.84,.76-1.34-.25-1.84-.76c-.5-.5-.76-1.12-.76-1.84v-7.89c-2.31,3.24-5.21,5.8-8.7,7.68-3.49,1.88-7.22,2.81-11.19,2.81-6.92,0-12.83-2.6-17.72-7.78-4.9-5.19-7.35-11.42-7.35-18.7s2.45-13.51,7.35-18.7c4.9-5.19,10.81-7.78,17.72-7.78,3.96,0,7.69,.94,11.19,2.81,3.49,1.88,6.39,4.4,8.7,7.57v-7.78c0-.72,.25-1.33,.76-1.84Zm-40.53,25.73c0,5.91,1.96,10.94,5.89,15.08,3.93,4.15,8.61,6.22,14.05,6.22s10.1-2.07,13.99-6.22c3.89-4.14,5.84-9.17,5.84-15.08s-1.95-10.95-5.84-15.14c-3.89-4.18-8.56-6.27-13.99-6.27s-10.12,2.09-14.05,6.27c-3.93,4.18-5.89,9.23-5.89,15.14Z"/>
                    <path d="M658.7,122.75V52.26c0-.72,.25-1.33,.76-1.84s1.12-.76,1.84-.76,1.35,.25,1.89,.76c.54,.51,.81,1.12,.81,1.84V122.75c0,.72-.27,1.34-.81,1.84-.54,.51-1.17,.76-1.89,.76s-1.33-.25-1.84-.76c-.51-.5-.76-1.12-.76-1.84Z"/>
                </g>
            </g>
        </svg>
    )
}

Logo.defaultProps = {
    dark: false
}

export default Logo