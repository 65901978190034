import React from "react"
import Footer from "./Footer"

import "../styles/main.scss"

const Layout = ({ hero, children, footer, showMap, parallax }) => (
  <div className={parallax ? "prlx__container" : "standard__container"}>
    {hero}
    <main>{children}</main>
    {footer ? <Footer showMap={showMap} /> : null}
  </div>
)

Layout.defaultProps = {
  showMap: true,
  footer: true,
  parallax: false,
}

export default Layout
