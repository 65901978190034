import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { trailingslashit } from "./helpers"

import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

import SectionHeading from "./SectionHeading"
import Image from "./image"
import CTAs from "./CTAs"
import SocialLink from "./SocialLink"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhoneAlt,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons"
import Copyright from "./Copyright"
import FooterMap from "./FooterMap"
library.add(faPhoneAlt, faEnvelope, faMapMarkerAlt)

const Footer = ({ data, showMap }) => {
  const footer = data.footer.edges[0].node

  return (
    <footer className="mt-5">
      <section>
        <SectionHeading
          crown={footer.crown}
          heading={footer.heading}
          align="center"
          className="mb-4"
        />
        <Container className="pb-5">
          <Row>
            {!showMap ? null : (
              <Col lg="6" sm="12">
                <FooterMap />
              </Col>
            )}
            <Col lg={showMap ? "6" : null} sm={showMap ? "12" : null}>
              <h5 className="mb-4">{footer.subhead}</h5>
              <p className="mb-4">{footer.body}</p>
              <Row>
                {footer.addresses.map(address => (
                  <Col key={address.heading} lg="6" sm="12">
                    <address className="address-wrapper">
                      <FontAwesomeIcon
                        icon={faMapMarkerAlt}
                        className="text-secondary"
                      />
                      <h3 className="h6 mb-1">{address.heading}</h3>
                      <div
                        dangerouslySetInnerHTML={{ __html: address.address }}
                      ></div>
                    </address>
                  </Col>
                ))}
              </Row>
              <Row>
                <Col lg="6" sm="12">
                  <div className="phone-wrapper">
                    <FontAwesomeIcon
                      icon={faPhoneAlt}
                      className="text-secondary"
                    />
                    <a
                      href={`tel:${footer.phone.replace(/-\(\)/g, "")}`}
                      className="text-secondary"
                    >
                      {footer.phone}
                    </a>
                  </div>
                  <div className="email-wrapper">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="text-secondary"
                    />
                    <a
                      href={`mailto:${footer.email}`}
                      className="text-secondary"
                    >
                      {footer.email}
                    </a>
                  </div>
                </Col>
                <Col lg="6" sm="12">
                  <CTAs ctas={footer.ctas} className="btn-block mt-2" />
                  <Button
                    href={trailingslashit("/careers/")}
                    variant="link"
                    className="text-secondary-light"
                    style={{ textTransform: "none" }}
                    block
                  >
                    Interested in working for Alloy?{" "}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="pt-5 pb-3">
          <Row>
            <Col className="text-center social">
              <Image
                src="/img/footer_logo.svg"
                width={35}
                className="mb-4 mt-4"
              />
              <ul className="list-inline">
                {footer.social_links.map(social => (
                  <li key={social.label} className="list-inline-item">
                    <SocialLink
                      icon={social.icon}
                      url={social.url}
                      label={social.label}
                      className="text-secondary mx-2"
                    />
                  </li>
                ))}
              </ul>
              <Copyright />
            </Col>
          </Row>
        </Container>
      </section>
    </footer>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        footer: allButterPage(filter: { slug: { eq: "footer" } }) {
          edges {
            node {
              crown
              heading
              subhead
              body
              addresses {
                heading
                address
              }
              phone
              email
              ctas {
                label
                url
                icon
                type
              }
              social_links {
                label
                icon
                url
              }
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
