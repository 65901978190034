import React from "react"

export const linesToListItems = lines => {
  return lines.split("\n").map(e => (!e.length ? null : <li>{e}</li>))
}

export const linesToListComma = lines => {
  return lines
    .split("\n")
    .map(e => (!e.length ? null : e.trim()))
    .join(", ")
}

export const dateFormat = date => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  return new Date(date).toLocaleDateString("en-US", options)
}

const untrailingslashit = url => {
  return url ? url.replace(/\/+$/, "") : null
}

export const trailingslashit = url => {
  if (url && (url.startsWith("mailto") || url.startsWith("#"))) {
    return url
  }
  return url ? untrailingslashit(url) + "/" : null
}

// Manipulating img src url to call on filestack processing image resize API
// https://www.filestack.com/docs/api/processing/#resize
export const getImgResizeUrl = (url, resizeWidth, resizeHeight) => {
  if (!url) return null

  const butterURL = "https://cdn.buttercms.com"

  if (url.startsWith(butterURL)) {
    let resize = ""
    const urlArr = url.split("/")
    const imgId = urlArr[3]

    if (resizeWidth) {
      resize = `resize=width:${resizeWidth}`
    } else if (resizeHeight) {
      resize = `resize=height:${resizeHeight}`
    } else {
      return null
    }
    return `${butterURL}/${resize}/fallback=file:${imgId}/${imgId}`
  }

  return null
}
