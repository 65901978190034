import React from 'react'

import { trailingslashit } from '../components/helpers'

import Navbar from 'react-bootstrap/Navbar'
import PrimaryNavItemDropdown from './PrimaryNavItemDropdown';
import { Link } from 'gatsby'
import Nav from 'react-bootstrap/Nav'

import nav from '../data/components/primary-nav.yml'

const PrimaryNavigation = () => {

    return(
    <>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
            <Nav className="ml-auto" aria-label="site">
                {nav.primaryNavItems.map(item => (
                    <>
                        { item.childItems ?
                            <PrimaryNavItemDropdown {...item}></PrimaryNavItemDropdown>
                            : 
                            <Link key={item.url} to={trailingslashit(item.url)} activeClassName="active" partiallyActive={true} className="nav-link">{item.label}</Link>
                        }
                    </>
                ))}
            </Nav>
        </Navbar.Collapse>
    </>
    )
}

export default PrimaryNavigation