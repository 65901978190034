import React, { useState } from 'react'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'

import { trailingslashit } from '../components/helpers'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const PrimaryNavItemDropdown = (item) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const location = useLocation();


    return (
        <div className="nav-dropdown position-relative">
            <button key={item.url} onClick={()=>setDropdownVisible(!dropdownVisible)}
                className={`${location.pathname.includes(trailingslashit(item.url)) ? "active" : ""} nav-link nav-dropdown-toggle`}
                aria-expanded={dropdownVisible}    
            >
                {item.label} <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
            </button>
            <div className={`${dropdownVisible ? "opened" : "closed"} nav-dropdown-menu`}>
                { item.childItems.map(childItem => (
                    <Link key={childItem.url} to={trailingslashit(childItem.url)} 
                    className="nav-link nav-dropdown-item"
                    tabIndex={dropdownVisible ? "" : -1}
                    >{childItem.label}</Link>   
                    ))}
                    <button className="close-btn" tabIndex={dropdownVisible ? "" : -1} onClick={()=>setDropdownVisible(false)}>&#10005;</button>
            </div>
            
        </div>
    )
}

export default PrimaryNavItemDropdown;