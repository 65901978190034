/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  description,
  lang,
  meta,
  title,
  image,
  path,
  type,
  typeData,
  date,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const schemaType = type

  let schemaJob

  if (schemaType === "job") {
    var expiresDate = new Date(date);
    expiresDate.setDate(expiresDate.getDate() + 90);

    schemaJob = {
      "@context": "http://schema.org/",
      "@type": "JobPosting",
      "employmentType": "FULL_TIME",
      datePosted: date,
      validThrough: expiresDate,
      description: typeData.job_description,
      hiringOrganization: {
        "@type": "Organization",
        name: "Alloy",
        sameAs: "https://alloy.digital/",
      },
      jobLocation: {
        "@type": "Place",
        address: {
          "@type": "PostalAddress",
          streetAddress: "101 25th Street North",
          addressLocality: "Birmingham",
          addressRegion: "AL",
          postalCode: "35203",
          addressCountry: "US",
        },
      },
      title: typeData.title,
    }
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      defaultTitle={site.siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: `${site.siteMetadata.siteUrl}${path || "/"}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image || site.siteMetadata.image,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: image || site.siteMetadata.image,
        },
      ].concat(meta)}
    >
      {schemaType === "job" && (
        <script type="application/ld+json">{JSON.stringify(schemaJob)}</script>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
