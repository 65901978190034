import React from 'react'
import PrimaryNav from './PrimaryNavigation'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'

import Logo from './Logo'

const Header = ({ dark }) => {
  const variant = dark ? "dark" : "light"
  
  return (
    <Navbar collapseOnSelect expand="lg" variant={variant}>
      <Container>
        <Navbar.Brand href='/'>
          <Logo dark={dark} />
        </Navbar.Brand>
        <PrimaryNav />
      </Container>
    </Navbar>
  )
}

export default Header